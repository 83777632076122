import { formatMMDDYYYYtoYYYYMMDD } from '@pumpkincare/shared';

function fileToClaimFile(file, document_type) {
  return {
    name: file.name,
    type: file.type,
    description: '',
    key: file.key,
    document_type: document_type,
  };
}

export function transformClaim({
  fileList,
  medicalRecordsFileList,
  dateList,
  injuryDate,
  totalAmountList,
  pets,
  claimType,
  isOngoing,
  isInsuranceAccident,
  isInsuranceIllness,
  isPreventiveEssentials,
  isMultiVet,
  selectedPet,
  selectedReimbursement,
  vetVisitReason,
  selectedVet,
}) {
  const fileUploadList = fileList
    .filter(file => file.error === undefined)
    .map(file => {
      return fileToClaimFile(file, 'invoice');
    })
    .concat(
      medicalRecordsFileList
        .filter(file => file.error === undefined)
        .map(file => {
          return fileToClaimFile(file, 'medical_record');
        })
    );

  const totalAmountClaimedList = totalAmountList.map(item =>
    Math.round(parseFloat(item.totalAmountClaimed) * 100)
  );

  const pet = pets.find(pet => pet.id === selectedPet);

  const claim = {
    is_estimate: claimType === 'estimate',
    is_accident: isInsuranceAccident,
    is_illness: isInsuranceIllness,
    is_prevent: isPreventiveEssentials,
    pet_id: selectedPet,
    claim_amounts: totalAmountClaimedList,
    send_reimbursement_to_vet: selectedReimbursement === 'my_vet',
    diagnosis_story: vetVisitReason,
    claim_attachments: fileUploadList,
    treatment_dates: dateList.map(item =>
      formatMMDDYYYYtoYYYYMMDD(item.dateOfVisit)
    ),
  };

  if (selectedVet && selectedVet !== 'other_vet') {
    claim.vet_id = selectedVet;
  }

  //ALLOW_MULTI_VET_PER_PET
  if ((isInsuranceAccident || isInsuranceIllness) && injuryDate) {
    claim.injury_date = formatMMDDYYYYtoYYYYMMDD(injuryDate);
  }

  //CLAIMS_UPDATES
  if (
    (isInsuranceAccident || isInsuranceIllness) &&
    typeof isOngoing === 'boolean' &&
    typeof isMultiVet === 'boolean'
  ) {
    claim.is_ongoing = isOngoing;
    claim.is_multi_vet = isMultiVet;
  }

  return { claim: claim, petName: pet.name };
}

export function transformAttachment(file, signedUrls) {
  const formData = new FormData();
  const signedUrlParams = signedUrls[file.name];

  file.key = signedUrlParams.fields.key;
  Object.keys(signedUrlParams.fields).forEach(key => {
    formData.append(key, signedUrlParams.fields[key]);
  });

  // Actual file has to be appended last.
  formData.append('file', file);

  return [signedUrlParams.url, formData];
}

function transformClaimInvoiceSubClaim(subClaim) {
  return {
    claimedAmount: subClaim.claimed_amount,
    id: subClaim.id,
    type: subClaim.type,
    paidAmount: subClaim.total_reimbursible,
  };
}

function transformClaimInvoicePet(pet) {
  return {
    age: pet.age,
    birthDate: pet.birth_date,
    breedCode: pet.breed_code,
    breedName: pet.breed_name,
    breedType: pet.breed_type,
    gender: pet.gender,
    hasMedicalRecords: pet.has_medical_records,
    id: pet.id,
    isPetPlanRenewalToAdult: pet.is_pet_plan_renewal_to_adult,
    medicalRecordsCount: pet.medical_records_count,
    name: pet.name,
    species: pet.species,
  };
}

export function transformClaimInvoice(claimInvoice) {
  return {
    claimedAmount: claimInvoice.claimed_amount,
    createdAt: claimInvoice.created_at,
    id: claimInvoice.id,
    lossDate: claimInvoice.loss_date,
    pet: transformClaimInvoicePet(claimInvoice.pet),
    petId: claimInvoice.pet.id,
    status: claimInvoice.status,
    summaryUrls: claimInvoice.summary_urls,
    isAccident: claimInvoice.is_accident,
    isIllness: claimInvoice.is_illness,
    isPrevent: claimInvoice.is_prevent,
    subClaims: claimInvoice.subclaims.map(transformClaimInvoiceSubClaim),
  };
}

export function transformClaimPayload(payload) {
  const {
    claimedAmounts,
    claimAttachments,
    diagnosisStory,
    hasPrevent,
    isAccident,
    isEstimate,
    isIllness,
    lossDate,
    medicalRecordsFileList,
    petId,
    sendReimbursementToVet,
    visits,
  } = payload;
  const claimedAmountsParsed = claimedAmounts.map(item =>
    Math.round(parseFloat(item.totalAmountClaimed) * 100)
  );
  return {
    claimed_amount: claimedAmountsParsed.reduce((sum, amount) => sum + amount, 0),

    claim_attachments: claimAttachments
      .map(file => fileToClaimFile(file, 'invoice'))
      .concat(
        medicalRecordsFileList.map(file => fileToClaimFile(file, 'medical_record'))
      ),

    diagnosis_story: diagnosisStory,
    is_accident: isAccident,
    is_estimate: isEstimate,
    is_illness: isIllness,
    is_prevent: hasPrevent,

    // Loss date doesn't exist for prevent-only claims
    loss_date: lossDate.length > 0 ? formatMMDDYYYYtoYYYYMMDD(lossDate) : null,

    pet_id: petId,
    send_reimbursement_to_vet: sendReimbursementToVet,

    visits: JSON.stringify(
      visits.map((visit, i) => ({
        claimed_amount: claimedAmountsParsed[i],
        date_of_visit: formatMMDDYYYYtoYYYYMMDD(visit.dateOfVisit),
      }))
    ),
  };
}

export function transformClaimPayloadWithHeaders(payload) {
  const { data, headers } = payload;
  const contentHeader = headers['content-range'];
  const [rangeData, claimsCount] = contentHeader?.split('/');
  const [rangeFrom, rangeTo] = rangeData
    .replace(/[^\d.-]/g, '')
    .split('-')
    .map(item => parseInt(item));

  return {
    claims: data.body.map(transformClaimInvoice),
    contentRange: { rangeFrom, rangeTo, claimsCount },
  };
}

export function sortClaims(claims, orders = 'asc') {
  return claims.sort(function (a, b) {
    return orders === 'desc'
      ? new Date(b.createdAt) - new Date(a.createdAt)
      : new Date(a.createdAt) - new Date(b.createdAt);
  });
}
