import { useQuery } from 'react-query';

import { getIsLoggedIn } from '@pumpkincare/shared';
import { getUserId, useUssr } from '@pumpkincare/user';

import { getAnswers } from './vets-service';

export const VET_FORM_QUERY = 'vetForm';

export function useVetForm(options) {
  const isLoggedIn = getIsLoggedIn();
  const { data: userId } = useUssr(getUserId);

  return useQuery(
    [VET_FORM_QUERY],

    () => getAnswers(userId),

    {
      enabled: isLoggedIn && !!userId,
      placeholderData: [],
      staleTime: Infinity,

      ...options,
    }
  );
}
