import { useState } from 'react';

export default function useTargetState(initialValue) {
  const [value, setValue] = useState(initialValue);

  const setTargetsValue = e => {
    setValue(e.target.value);
  };

  return [value, setTargetsValue];
}
