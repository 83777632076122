import { useQuery } from 'react-query';

import { getUserId, useUssr } from './user-self-query';
import { fetchUserVets } from './user-service';
import { transformUserVets } from './user-utils';

export const USER_VETS_QUERY = 'userVets';

export const getUserVetsCanViewData = (vets = []) =>
  vets.filter(vet => vet.permissions.can_view_data);

export function useUserVets(select) {
  const { data: userId } = useUssr(getUserId);

  return useQuery(
    USER_VETS_QUERY,

    () => fetchUserVets(userId).then(transformUserVets),

    {
      enabled: !!userId,
      placeholderData: [],
      select,
    }
  );
}
