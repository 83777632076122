export function getStripeError(err) {
  const errorCode = err.response?.data?.message || 'generic_error';

  return {
    insufficient_funds: 'This card has insufficient funds.',
    incorrect_address: 'This billing address is incorrect.',
    incorrect_cvc: 'Incorrect cvc code.',
    incorrect_number: 'Incorrect card number.',
    incorrect_zip: 'Incorrect Zip Code.',
    invalid_card_type: 'Invalid card type.',
    prepaid_card: 'Invalid card type.',
    generic_error:
      'There was an error processing your payment. Please review your information and try an alternative card.',
  }[errorCode];
}
