import { useQuery } from 'react-query';

import { getMonthlyStatements } from './user-service';

const MONTHLY_STATEMENTS_QUERY = 'monthlyStatements';

export function useMonthlyStatements(select) {
  return useQuery([MONTHLY_STATEMENTS_QUERY], getMonthlyStatements, {
    placeholderData: [],
    select,
  });
}
