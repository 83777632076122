import { useMutation, useQueryClient } from 'react-query';

import { transformClaimPayload } from './claim-utils';
import { CLAIMS_QUERY } from './claims-query';
import { postClaim } from './claims-service';

export function useFileClaim() {
  const queryClient = useQueryClient();

  return useMutation(
    claim => postClaim(transformClaimPayload(claim)),

    {
      // Always re fetch after error or success
      onSettled: () => queryClient.invalidateQueries([CLAIMS_QUERY]),
    }
  );
}
