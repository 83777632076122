import { useQuery } from 'react-query';

import { getUserId, useUssr } from './user-self-query';
import { getUserBranding } from './user-service';

export const USER_BRANDING_QUERY = 'userBrandingQuery';

export function useUserBranding() {
  const { data: userId } = useUssr(getUserId);

  return useQuery(
    [USER_BRANDING_QUERY, userId],

    () => getUserBranding(userId),

    {
      enabled: !!userId,
      placeholderData: {
        id: '',
        partner_id: '',
        primary_logo_url: '',
        secondary_logo_url: '',
      },
    }
  );
}
