import { useMutation, useQueryClient } from 'react-query';

import { USER_PROFILE_QUERY } from './user-profile-query';
import { updateUserPaperless } from './user-service';

export function useMutateUserPaperless() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ is_paperless, terms_version }) =>
      updateUserPaperless({ is_paperless, terms_version }),
    {
      onSettled: () => queryClient.invalidateQueries([USER_PROFILE_QUERY]),

      onMutate: async variables => {
        await queryClient.cancelQueries([USER_PROFILE_QUERY]);

        const profileData = queryClient.getQueryData([USER_PROFILE_QUERY]);
        const { is_paperless, terms_version } = variables;

        queryClient.setQueryData([USER_PROFILE_QUERY], {
          ...profileData,

          is_paperless,
          terms_version,
        });

        return { prevData: profileData };
      },
    }
  );
}
