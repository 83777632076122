import { useQuery } from 'react-query';

import { getClaims } from './claims-service';

export const CLAIMS_QUERY = 'claims';

export function useClaims(filter, range) {
  return useQuery(
    [CLAIMS_QUERY, filter, range],

    async () => getClaims(filter, range),

    {
      placeholderData: {
        claims: [],
        contentRange: {
          rangeFrom: 0,
          rangeTo: 9,
          claimsCount: 10,
        },
      },

      onSuccess: () => {},
    }
  );
}
