import { Auth } from 'aws-amplify';
import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';
import { responseDataBody } from '@pumpkincare/shared';

import { transformClaimPayloadWithHeaders } from './claim-utils';

export function downloadClaimDocument(type, claimId) {
  let url;

  if (type === 'Insurance') {
    url = `${POLICY_BASE_API}/users/protect-claim-eob/${claimId}`;
  } else {
    url = `${POLICY_BASE_API}/users/prevent-claim-summary/${claimId}`;
  }

  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      })
      .then(response => response.data.body.url)
  );
}

export function submitClaim(claim) {
  return Auth.currentAuthenticatedUser().then(user => {
    return axios.post(`${POLICY_BASE_API}/users/claims`, claim, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    });
  });
}

export const ClaimsService = {
  downloadClaimDocument,
  submitClaim,
};

export function getClaims(filter, range) {
  const rangeParam =
    range?.length > 0 ? `range=${encodeURIComponent(JSON.stringify(range))}` : '';

  const filterParam =
    Object.keys(filter)?.length > 0
      ? `&filter=${encodeURIComponent(JSON.stringify(filter))}`
      : '';

  return Auth.currentAuthenticatedUser().then(user => {
    return axios
      .get(`${POLICY_BASE_API}/claims?${rangeParam}${filterParam}`, {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      })
      .then(transformClaimPayloadWithHeaders);
  });
}

export function postClaim(claim) {
  return Auth.currentAuthenticatedUser().then(user => {
    return axios
      .post(`${POLICY_BASE_API}/claims`, claim, {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      })
      .then(responseDataBody);
  });
}
